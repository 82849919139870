// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\matt\\code\\tbtfuture.fyi\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\matt\\code\\tbtfuture.fyi\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\matt\\code\\tbtfuture.fyi\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("C:\\Users\\matt\\code\\tbtfuture.fyi\\src\\pages\\map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-on-demand-js": () => import("C:\\Users\\matt\\code\\tbtfuture.fyi\\src\\pages\\on-demand.js" /* webpackChunkName: "component---src-pages-on-demand-js" */),
  "component---src-pages-survey-js": () => import("C:\\Users\\matt\\code\\tbtfuture.fyi\\src\\pages\\survey.js" /* webpackChunkName: "component---src-pages-survey-js" */)
}

exports.data = () => import("C:\\Users\\matt\\code\\tbtfuture.fyi\\.cache\\data.json")

